<template>
	<v-container fixed>
		<v-layout justify-center wrap>
			<Progress :error="error" :loading="loading" type="overlay"></Progress>
			<v-flex xs12 md8 v-if="user">
				<material-card color="info" title="Профиль">
					<v-form ref="form" v-model="valid" @submit.prevent="submit">
						<v-container py-0>
							<v-layout wrap>
								<v-flex xs12 v-if="user.type == -2">
									<v-alert type="info">
										Ваша учётная запись не активирована. Если вам не пришло на почту письмо активации, нажмите <v-btn text @click="actResend">сюда</v-btn>
									</v-alert>
								</v-flex>
								<v-flex xs12>
									<v-alert :value="!!info" :type="alertType" dismissible>{{ info }}</v-alert>
								</v-flex>
								<!--v-flex xs12 md4>
									<v-text-field class="purple-input" label="Имя" v-model="user.name"/>
								</v-flex>-->
								<v-flex xs12 md4>
									<v-text-field
										class="purple-input"
										label="Email"
										:rules="emailRules"
										v-model="user.email"
										:error-messages="mess.email"
									/>
								</v-flex>
								<v-flex xs12 md4>
									<v-text-field
										label="Изменить пароль"
										class="purple-input"
										:rules="[rule_password, rule_length_max(20)]"
										v-model="user.password"
										ref="password"
										:error-messages="mess.password"
										type="password"
									/>
								</v-flex>
								<!--<v-flex xs12 md4>
									<v-text-field
										label="Ник"
										class="purple-input"
										v-model="user.login"
										:rules="[rule_login, rule_length_min(3), rule_length_max(20)]"
										:error-messages="mess.login"
									/>
								</v-flex>
								<v-flex xs12 md4>
									<v-text-field label="Город" class="purple-input" v-model="user.location"/>
								</v-flex>-->
								<v-flex xs12 text-xs-right>
									<v-btn
										:disabled="!valid"
										class="mx-0 font-weight-light"
										type="submit"
										color="success"
									>Сохранить</v-btn>
								</v-flex>
							</v-layout>
						</v-container>
					</v-form>
				</material-card>
				<material-card class="layer" color="info" title="Настройки">
					<v-container py-0>
						<v-layout wrap>
							<v-flex xs12 md4>
								<v-switch v-model="settings.series_no_forward" @change="submitSettings('series_no_forward')" label="Не перематывать на следующую серию"/>
							</v-flex>
							<v-flex xs12 md4>
								<v-switch v-model="settings.follow_autoremove" @change="submitSettings('follow_autoremove')" label="Удалять фильм из 'Посмотреть позже' после просмотра"/>
							</v-flex>
							<v-flex xs12 md4>
								<v-switch v-model="settings.notify_email_series" @change="submitSettings('notify_email_series')" label="Присылать обновления на почту"/>
							</v-flex>
							<v-flex xs12 md4>
								<notification />
							</v-flex>
							<v-flex xs12 md4>
								<v-switch v-model="settings.layout" @change="submitSettings('layout')" label="Темная тема"/>
							</v-flex>
							<v-flex xs12 md8 v-if="!oauth_link.includes(1) || !oauth_link.includes(3)">
								Вы можете привязать авторизацию через соц. сети: <login-social :oauth_link="oauth_link" />
							</v-flex>
						</v-layout>
					</v-container>
				</material-card>
			</v-flex>
			<v-flex xs12 md3 offset-md1 v-if="user && user.avatar" v-show="!loading">
				<material-card class="v-card-profile">
					<image-dialog
						:image_original="getPhotoPath('user', user.id) + user.avatar + '_original.jpg'"
						:image="getPhotoPath('user', user.id) + user.avatar + '.jpg'"
					></image-dialog>
					<v-card-text class="text-center"></v-card-text>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import ImageDialog from "../../components/helper/image-dialog.vue";
import LoginSocial from "../../components/login-social.vue";
import Notification from "../../components/helper/notification.vue";
import { Helper } from "../../mixins/Helper";
import { Validator } from "../../mixins/Validator";

export default {
	mixins: [Helper, Validator],
	components: { ImageDialog, LoginSocial, Notification },
	data() {
		return {
			loading: false,
			user: {
				name: "",
				login: "",
				password: "",
				email: "",
				location: ""
			},
			settings: {
				series_no_forward: null,
				notify_email_series: null,
				layout: null
			},
			error: null,
			valid: true,
			info: null,
			alertType: 'info',
			mess: {
				email: [],
				password: [],
				login: []
			},
			oauth_link: []
		};
	},
	created() {
		let t = this;
		t.fetchData();
	},
	computed: {},
	watch: {
		"user.email": function() {
			this.mess.email = [];
		},
		"user.login": function() {
			this.mess.login = [];
		},
		"user.password": function() {
			this.mess.password = [];
		},
		"settings.layout": function(val) {
			this.$vuetify.theme.dark = val;
			this.$store.commit("user/setTheme", val);
		}
	},
	methods: {
		fetchData() {
			let t = this;
			t.error = t.user = t.info = null;
			t.loading = true;

			window.ajax("/api/user/get", {}, function(data, error) {
				t.loading = false;
				if (!error) {
					t.user = data.user;
					t.settings = data.settings;
					t.oauth_link = data.oauth;
					if (t.$route.query && t.$route.query.reset == 'password') {
						t.valid = false;
						t.mess.password = "Пожалуйста, поменяйте пароль";
						//t.info = "Пожалуйста, поменяйте пароль";
					}
					if (t.$route.query && t.$route.query.reset == 'activate') {
						t.info = "Проверка электронной почты произведена успешно.";
						t.alertType = 'success';
					}
				} else {
					t.error = data;
				}
			});
		},
		submit() {
			let t = this;
			t.loading = true;

			window.ajax(
				"/api/user/save",
				{ body: JSON.stringify(t.user) },
				function(data) {
					t.loading = false;

					if (data.errors) {
						let errors = data.errors;
						for (let field in errors) {
							t.mess[field] = errors[field];
						}
					} else {
						t.alertType = 'success';
						if (data.message) t.info = data.message;
						else t.info = "Ваши данные сохранены";
					}
				}
			);
		},
		submitSettings(field) {
			let t = this;

			window.ajax(
				"/api/user/save-settings",
				{ body: JSON.stringify({field: field, value: t.settings[field]}) },
				function() {
					t.alertType = 'success';
					t.info = "Ваши настройки сохранены";
				}
			);
		},
		actResend() {
			let t = this;

			window.ajax(
				"/api/user/resend",
				{},
				function(data) {
					if (!data.error) {
						t.alertType = 'success';
						t.info = "Отправлено повторное письмо активации";
					} else {
						t.alertType = 'error';
						t.info = data.error;
					}
				}
			);
		}
	}
};
</script>