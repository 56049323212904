<template>
	<v-switch v-if="notificationsSupported" @click="toggleSubscription" :loading="loading" :disabled="loading" v-model="notificationsEnabled" label="Уведомления на этом браузере"/>
</template>
<script>
export default {
	data() {
		return {
			notificationsSupported: false,
			notificationsEnabled: false,
			subscription: null,
			serviceWorkerRegistation: null,
			loading: false
		};
	},
	methods: {
		toggleSubscription() {
			let t = this;
			if (!t.notificationsSupported || t.loading) return;

			t.loading = true;
			if (!t.notificationsEnabled) {
				Notification.requestPermission(result => {
					if (result !== "granted") {
						alert(
							"Вы не разрешили слать уведомления. Мы не будем."
						);
					} else {
						t.createSubscription().then(sub => {
							t.subscription = sub;
							window.ajax(
								"/api/push/subscribe",
								{ body: { subscription: t.subscription } },
								function(data) {
									if (!data.success) return;
									t.loading = false;
									t.notificationsEnabled = true;
								}
							);
						});
					}
				});
			} else {
				if (t.subscription !== null) {
					window.ajax(
						"/api/push/destroy",
						{ body: { endpoint: t.subscription.endpoint } },
						function(data) {
							if (!data.success) return;
							t.subscription.unsubscribe().then(() => {
								t.loading = false;
								t.notificationsEnabled = false;
								t.subscription = null;
							});
						}
					);
				}
			}
		},
		createSubscription() {
			if (this.serviceWorkerRegistation === null) {
				return navigator.serviceWorker.ready // returns a Promise, the active SW registration
					.then(swreg => {
						this.serviceWorkerRegistation = swreg;
						return this.subscribe(this.serviceWorkerRegistation);
					});
			} else {
				return this.subscribe(this.serviceWorkerRegistation);
			}
		},
		subscribe(swreg) {
			// create new subscription for this browser on this device
			const vapidPublicKey = 'BAnlsj39xYB+Q60nIh/iYqpS/1tCx6Ft0O1hOMAVY4ZXKvFXZa8gfso0eeFyVCBT5wCqW/fWlaDMggDAVEOJ1xA=';
			const convertedVapidPublicKey = this.urlBase64ToUint8Array(
				vapidPublicKey
			);
			// return the subscription promise, we chain another then where we can send it to the server
			return swreg.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: convertedVapidPublicKey
			});
		},
		getSubscription(swreg) {
			return swreg.pushManager.getSubscription();
		},
		findSubscription() {
			return navigator.serviceWorker.ready.then(swreg => {
				this.serviceWorkerRegistation = swreg;
				return this.getSubscription(this.serviceWorkerRegistation);
			});
		},
		urlBase64ToUint8Array(base64String) {
			const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
			const base64 = (base64String + padding)
				.replace(/-/g, "+")
				.replace(/_/g, "/");
			const rawData = window.atob(base64);
			let outputArray = new Uint8Array(rawData.length);
			for (let i = 0; i < rawData.length; ++i) {
				outputArray[i] = rawData.charCodeAt(i);
			}
			return outputArray;
		}
	},
	created() {
		if ("Notification" in window && "serviceWorker" in navigator) {
			this.notificationsSupported = true;
		}

		this.findSubscription().then(sub => {
			this.loading = false;
			if (sub === null) {
				this.notificationsEnabled = false;
			} else {
				this.notificationsEnabled = true;
				this.subscription = sub;
			}
		});
	}
};
</script>
